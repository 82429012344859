import Component from "./component";

class Modals extends Component {
    private showActuators: HTMLElement[];
    private hideActuators: HTMLElement[];
    
    constructor(element: HTMLElement) {
        super(element);
        this.showActuators = [];
        this.hideActuators = [];

        document.querySelectorAll(`[data-modal="${this.containerElement.id}"]`).forEach(item => {
            this.showActuators.push(item as HTMLElement);
        });

        this.containerElement.querySelectorAll('[data-close]').forEach(item => {
            this.hideActuators.push(item as HTMLElement);
        });

        this.setEvents();
    }

    private setEvents() {
        this.showActuators.forEach(item => item.addEventListener('click', () => this.show()));
        this.hideActuators.forEach(item => item.addEventListener('click', () => this.hide()));

        document.addEventListener('click', (e) => {
            const element = e.target as HTMLElement;
            (element.dataset.modal || element.parentElement?.dataset.modal) == this.containerElement.id && this.show();
            element.id == this.containerElement.id && this.hide();
        });
    }

    private show() {
        this.containerElement.scrollTop = 0;
        this.containerElement.classList.add('show');
        setTimeout(() => this.containerElement.classList.add('active'), 50);
    }

    private hide() {
        this.containerElement.classList.remove('active');
        setTimeout(() => this.containerElement.classList.remove('show'), 300);
    }

    public static init() {
        const elements = document.querySelectorAll('.modal');
        elements.forEach(element => new Modals(element as HTMLElement));
    }
}

export default Modals;