class Form {
    protected form: HTMLFormElement;
    protected fields: HTMLFormControlsCollection;
    protected save: HTMLButtonElement;

    constructor(formId = 'form', saveButtonId = 'save') {
        this.form = document.getElementById(formId) as HTMLFormElement;
        this.fields = this.form.elements;
        this.save = document.getElementById(saveButtonId) as HTMLButtonElement;
        this.save.addEventListener('click', () => this.set());
    }

    protected hydrate(data: any) {
        Object.entries<string>(data).forEach(([key, value]) => {
            const field = document.getElementById(key) as HTMLInputElement;
            if (field) field.value = value;
        });
    }

    protected set() {}

    protected getValues<T>() {
        const data = {};
        Object.entries(this.fields).forEach(([index, element]) => {
            data[element.id] = (element as HTMLInputElement).value;
        });

        return data as T;
    }

    protected validate(fields: any) {
        this.resetErrors();

        let error = false; 
        Object.entries<Function>(fields).forEach(([field, isValid]) => {
            if (!isValid(this.form[field].value)) {
                error = true;
                this.error(field);
            }
        });

        return error;
    }

    protected success() {
        this.save.classList.add('btn-success');
        setTimeout(() => this.save.classList.remove('btn-success'), 2000);
    }

    protected error(field: string) {
        this.fields[field].classList.add('has-error');
    }

    protected resetErrors() {
        document.querySelectorAll('.has-error').forEach(item => item.classList.remove('has-error'));
    }
}

export default Form;