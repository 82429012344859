import Accordion from './components/accordion';
import Menu from './components/menu';
import Modals from './components/modals';
import Tabs from './components/tabs';

class Claudia {
    private settings: claudiaSettings;
    private components: claudiaFunctions;
    public static breakpoints = {
        'sm': 576,
        'md': 768,
        'lg': 992,
        'xl': 1200,
    };

    constructor(settings?: claudiaSettings) {
        this.components = {
            accordion: () => Accordion.init(),
            menu: () => Menu.init(),
            modals: () => Modals.init(),
            tabs: () => Tabs.init(),
        };

        this.settings = settings || {
            accordion: false,
            menu: false,
            modals: false,
            tabs: false,
        };

        this.init();
    }

    private init() {
        Object.entries(this.settings).forEach(item => {
            item[1] && this.components[item[0]]();
        });
    }
}

type claudiaComponents = 'accordion' | 'menu' | 'modals' | 'tabs';

type claudiaSettings = {
    [key in claudiaComponents]?: boolean
};

type claudiaFunctions = {
    [key in claudiaComponents]: Function
};

export default Claudia;
