import { gbLoader } from '../helpers';
import { validate, resetErrors } from '../validate';
import Api from '../api';

const contact = () => {
    const form = document.querySelector<HTMLFormElement>('#formContact');
    const name = document.querySelector<HTMLInputElement>('#nombre');
    const email = document.querySelector<HTMLInputElement>('#email');
    const phone = document.querySelector<HTMLInputElement>('#telefono');
    const message = document.querySelector<HTMLTextAreaElement>('#consulta');
    const terms = document.querySelector<HTMLInputElement>('#politica');

    resetErrors();
    

    if (email && !validate(email.value, 'email')) {
        handleError('El emáil no es válido', email);
        return;
    }

    if (terms && !terms.checked) {
        handleError('Debe aceptar este campo', terms);
        return;
    }

    gbLoader();

    Api.postContact({
        nombre: name?.value || '',
        email: email?.value || '',
        telefono: phone?.value || '',
        consulta: message?.value || '',
        politica: terms?.checked ? 1 : 0,
    }).then(() => {
        form?.reset();
        document.querySelector('.form-success')?.classList.remove('hide');
    })
    .finally(() => gbLoader(false));
}

const handleError = (text: string, element: HTMLElement) => {
    const message = document.createElement('p');
    message.className = 'message error';
    message.innerText = text || 'Se ha producido un error';

    if (element) {
        element.classList.add('has-error');
        element.parentElement?.append(message);
    }
};

export const formContact = () => {
    const button = document.getElementById('submitContact');
    button && button.addEventListener('click', (e: Event) => {
        e.preventDefault();
        contact();
    });
};