import { btnSuccess, gbLoader, uuid } from './helpers';
import { gbPrint } from './partials/print';
import { loginRequired } from './partials/login';
import { runEvents } from './partials/events';
import Claudia from 'Claudia';
import gbApi from './api';

const ww = (window || global) as any;
ww.btnSuccess = btnSuccess;
ww.gbLoader = gbLoader;
ww.gbPrint = gbPrint;
ww.loginRequired = loginRequired;
ww.gbApi = gbApi;
ww.uuid = uuid;

new Claudia({
    accordion: true,
    menu: true,
    modals: true,
    tabs: true,
});

runEvents();