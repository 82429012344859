export const isRequired = (field: string) => field !== '';
export const isEmail = (field: string) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(field);
export const isPassword = (field: string) => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(field);
export const isAlphanumeric = (field: string) => /^[0-9a-zA-Z]+$/.test(field);
export const isNumeric = (field: string) => {
    const number = Number(field);
    return !isNaN(number) && number > 0;
};

export const validate = (field: string, type?: ValidationType): boolean => {
    const check: checkValidator = {
        required: isRequired,
        email: isEmail,
        password: isPassword,
        alphanum: isAlphanumeric,
        numeric: isNumeric,
    }

    return check[type || 'required'](field);
}

export const formValidation = (field: string) => {
    resetErrors();
    
    const input = document.getElementById(field);
    input?.classList.add('has-error');
}

export const resetErrors = () => {
    const message = document.querySelectorAll('.message');
    message && message.forEach(item => item.remove());

    const errors = document.querySelectorAll('.has-error');
    errors && errors.forEach(item => {
        item.classList.remove('has-error');
    });
}

type checkValidator = {
    [key in ValidationType]: (field: string) => boolean
}

export type ValidationType = 'required' | 'email' | 'password' | 'alphanum' | 'numeric';