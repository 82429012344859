import html2canvas from 'html2canvas';
import { gbLoader, isMobile } from '../helpers';
import { redirectLogin } from './login';
import { jsPDF } from 'jspdf';
import Api from '../api';

const createCanvas = (type: Report, container: HTMLElement) => {
    html2canvas(container).then(canvas => {
        const data = canvas.toDataURL('image/png');
        createPDFfromImage(type, data);
        container.remove();
    });
};

const createPDFfromImage = (type: Report, data: string) => {
    const format = type === 'ticket' ? [160, 300] : 'a4';
    const pdf = new jsPDF('p', 'px', format);
    const imgProps = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight, undefined, 'FAST');
    openPDF(pdf);
};

const openPDF = (pdf: jsPDF) => {
    const output = pdf.output('blob');
    const data = URL.createObjectURL(output);

    if (isMobile()) {
        const link = document.createElement('a');
        link.download = 'invoice.pdf';
        link.href = data;
        link.click();
    } else {
        window.open(data);
    }

    gbLoader(false);
};

const loadLogo = async (url: string): Promise<string | null> => {
    return new Promise((resolve) => {
        let img = new Image();
        img.onload = () => resolve(url);
        img.onerror = () => resolve(null);
        img.src = url;
    });
};

const getTemplate = async (type: Report, data: any): Promise<string> => {
    const template = type == 'ticket' ?
        require('Templates/print/ticket.twig') :
        require('Templates/print/invoice.twig');

    if (data.logoUrl) {
        const image = await Api.postImage(data.logoUrl);
        if (image) {
            data.logo = await loadLogo(image);
        }
    }
    
    const html = template({ type, data });
    return html;
};

export const gbPrint = async (type: Report, data: any) => { 
    gbLoader();

    const content = document.createElement('div');
    content.className = `page-${type === 'ticket' ? 'ticket' : 'invoice'}`;
    content.innerHTML = await getTemplate(type, data);
    document.body.appendChild(content);

    createCanvas(type, content);
};

export const printOrder = () => {
    const path = window.location.pathname;
    const order = path.substring(path.lastIndexOf('/') + 1);
    
    Api.getBill(order)
        .then((res) => {
            if (res.billID) {
                gbPrint('invoice', res);
            } else {
                redirectLogin('order');
            }
        });
}

type Report = 'invoice' | 'budget' | 'ticket';
