import Component from "./component";

class Menu extends Component {
    private toggleElement: HTMLElement;
    
    constructor(element: HTMLElement) {
        super(element);
        this.toggleElement = this.containerElement.querySelector('.toggle') as HTMLElement;

        this.setEvents();
    }

    private setEvents() {
        this.toggleElement.addEventListener('click', () => document.body.classList.toggle('menu'));
    }

    public static init() {
        const elements = document.querySelectorAll('.nav-menu');
        elements.forEach(element => new Menu(element as HTMLElement));
    }
}

export default Menu;