import { ERROR, STATUS } from './constants';
import { configurationData } from './components/Configuration';
import { logout } from './partials/login';
import { formValidation } from './validate';

class Api {
    private readonly url = '/v1/';
    private token: string = '';

    constructor() {
        this.token = localStorage.getItem('gbToken') || '';
    }

    public doLogin = async (data: loginData) => {
        return await this.fetch('user/login', 'GET', data)
            .then(res => {
                if (res.status === STATUS.OK) return { success: true, token: res.body.token };
                return { success: false, error: this.errorHandler(res.body) };
            });
    }

    public doRegister = async (data: loginData) => {
        return await this.fetch('user/registration', 'POST', data)
            .then(res => {
                if (res.status === STATUS.CREATED) return { success: true };
                return { success: false, error: this.errorHandler(res.body) };
            });
    }

    public doForgot = async (data: loginData) => {
        return await this.fetch('user/password/reset', 'POST', data)
            .then(res => {
                if (res.status === STATUS.CREATED) return { success: true };
                res.body.errorMessage = 'No user found';
                return { success: false, error: this.errorHandler(res.body) };
            });
    }

    public doReset = async (token: string, data: resetPasswordData) => {
        return await this.fetch(`user/password/reset/${token}`, 'POST', data)
            .then(res => {
                if (res.status === STATUS.CREATED) return { success: true };
                return { success: false, error: this.errorHandler(res.body) };
            });
    }

    public getConf = async () => {
        return await this.fetch('configuration', 'GET')
            .then(res => {
                if (res.status === STATUS.OK) return res.body as configurationData;
                return null;
            });
    }

    public putConf = async (conf: configurationData) => {
        return await this.fetch('configuration', 'PUT', conf)
            .then(res => {
                if (res.status === STATUS.EMPTY) return { success: true };
                return { success: false, error: res.body.param };
            });
    }

    public getCustomers = async () => {
        return await this.fetch('customer/', 'GET')
            .then(res => {
                if (res.status === STATUS.OK) return res.body;
                return [];
            });
    }

    public getCustomer = async (customerId: string) => {
        return await this.fetch(`customer/${customerId}`, 'GET')
            .then(res => {
                if (res.status === STATUS.OK) return res.body;
                return [];
            });
    }

    public putCustomer = async (customer: customerData) => {
        return await this.fetch(`customer/${customer.customerID}`, 'PUT', customer)
            .then(res => {
                if (res.status === STATUS.EMPTY) return { success: true };
                return { success: false, error: res.body.errorMessage };
            });
    }

    public delCustomer = async (customerId: string) => {
        return await this.fetch(`customer/${customerId}`, 'DELETE')
            .then(res => {
                if (res.status === STATUS.OK) return { success: true };
                return { success: false, error: this.errorHandler(res.body) };
            });
    }

    public getProducts = async (params?: productSearchParams) => {
        return await this.fetch('product/', 'GET', params)
            .then(res => {
                if (res.status === STATUS.OK) return res.body;
                return [];
            });
    }

    public getProduct = async (productId: string) => {
        return await this.fetch(`product/${productId}`, 'GET')
            .then(res => {
                if (res.status === STATUS.OK) return res.body;
                return [];
            });
    }

    public putProduct = async (product: productData) => {
        return await this.fetch(`product/${product.productID}`, 'PUT', product)
            .then(res => {
                if (res.status === STATUS.EMPTY) return { success: true };
                return { success: false, error: res.body.errorMessage };
            });
    }

    public delProduct = async (productID: string) => {
        return await this.fetch(`product/${productID}`, 'DELETE')
            .then(res => {
                if (res.status === STATUS.OK) return { success: true };
                return { success: false, error: this.errorHandler(res.body) };
            });
    }

    public getBills = async () => {
        return await this.fetch('bill/', 'GET')
            .then(res => {
                if (res.status === STATUS.OK) return res.body;
                return [];
            });
    }

    public getBill = async (billId: string) => {
        return await this.fetch(`bill/${billId}`, 'GET')
            .then(res => {
                if (res.status === STATUS.OK) return res.body;
                return [];
            });
    }

    public postBill = async (bill: billData) => {
        return await this.fetch(`bill/${bill.billID}`, 'POST', bill)
            .then(res => {
                if (res.status === STATUS.CREATED) return { success: true };
                return { success: false, error: res.body.param };
            });
    }

    public getBudgets = async () => {
        return await this.fetch('budget/', 'GET')
            .then(res => {
                if (res.status === STATUS.OK) return res.body;
                return [];
            });
    }

    public getBudget = async (budgetId: string) => {
        return await this.fetch(`budget/${budgetId}`, 'GET')
            .then(res => {
                if (res.status === STATUS.OK) return res.body;
                return [];
            });
    }

    public putBudget = async (budget: billData) => {
        return await this.fetch(`budget/${budget.billID}`, 'PUT', budget)
            .then(res => {
                if (res.status === STATUS.EMPTY) return { success: true };
                return { success: false, error: res.body.param };
            });
    }

    public delBudget = async (budgetId: string) => {
        return await this.fetch(`budget/${budgetId}`, 'DELETE')
            .then(res => {
                if (res.status === STATUS.OK) return { success: true };
                return { success: false, error: res.body.errorMessage };
            });
    }

    public postImage = async (url: string) => {
        return await this.fetch('image', 'POST', { url }, true)
            .then(res => {
                if (res.status === STATUS.OK) return URL.createObjectURL(res.body);
                return false;
            });
    }

    public postContact = async (data: contactData) => {
        return await this.fetch('contact', 'POST', data)
            .then(res => {
                if (res.status === STATUS.OK) return { success: true };
                return { success: false, error: res.body.errorMessage };
            });
    }

    private errorHandler = (res: any): string => {
        const errors: any = {
            'User and password does not match': 'Los datos no son correctos',
            'No user found': 'Este usuario no existe',
            'This email is already registered': 'Ya existe un usuario registrado con este email',
            'Confirmation email not sent': 'Ha habido un problema con el registro. Inténtelo de nuevo o pruebe con otro email.'
        };

        return errors[res.message] || 'Se ha producido un error';
    }

    public apiError = (response: globalThis.Response, body?: BodyResponse) => {
        if (body) {
            const errorCode = body.code || ERROR.UNKNOWN;
            console.error(`${response.status} ${body.message || ''}`);
    
            if (response.status === STATUS.UNAUTHORIZED) {
                logout();
            } else if (response.status === STATUS.ERROR) {
                alert('Se ha producido un error. Inténtelo de nuevo o pruebe más tarde.');
            } else if (errorCode === 'CONFLICT' && body.message) {
                alert(body.message);
            } else if ((errorCode === ERROR.BAD_REQUEST || errorCode === ERROR.UNPROCESSABLE_ENTITY) && body.param) {
                formValidation(body.param);
            }
        }
    }

    public apiDebug = (params: any) => {
        const message = JSON.stringify(params);
        alert(message);
    }

    private fetch = async (endpoint: string, method: Methods, data?: any, toBlob?: boolean) => {
        const params: RequestInit = {
            method,
            headers: {
                'content-type': 'application/json; charset=utf-8',
            }
        };

        if (this.token !== '') {
            params.headers = {
                ...params.headers,
                'Authorization': `Bearer ${this.token}`,
            }
        }

        if (data) {
            if (method === 'GET') {
                const query = Object.keys(data).map(key => {
                    return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
                }).join('&');

                endpoint = endpoint.concat('?', query);
            } else {
                params.body = JSON.stringify(data);
            }
        }

        const res = await fetch(`${this.url}${endpoint}`, params);

        try {
            const body = await (toBlob ? res.blob() : res.json());
            if (!res.ok) this.apiError(res, body);
            return { success: res.ok, status: res.status, body };
        } catch (err) {
            this.apiError(res);
            return res;
        }
    }
}

type Methods = 'GET' | 'POST' | 'PUT' | 'DELETE';

interface BodyResponse {
    code: string;
    message: string;
    param?: string;
}

interface customerData {
    customerID: string;
    nif: string;
    name: string;
    surname: string;
    enterprise: string;
    address: string;
    zipCode: number;
    state: string;
    city: string;
    country: string;
    email: string;
    phone: string;
    discontinue: 0 | 1;
}

interface productData {
    productID: string;
    sku: string;
    description: string;
    price: number;
    tax: number;
    stock: number;
    discontinue: 0 | 1;
}

interface productSearchParams {
    sku: string;
    search: 'equalTo' | 'contains' | 'startContains'
}

interface billData {
    billID: string;
    breakdowns: string;
    customer: customerData;
    fiscalData: configurationData;
    total: number;
    date: Date;
    details: billDetail[];
    notes: string;
}

interface billDetail {
    amount: number;
    line: number;
    price: number;
    sku: string;
    description: string;
    subtotal: number;
    tax: number;
}
interface loginData {
    email: string;
    password: string;
}

interface resetPasswordData {
    newPassword: string;
    newPasswordConfirmation: string;
}

interface contactData {
    nombre: string;
    email: string;
    telefono: string;
    consulta: string;
    politica: 0 | 1;
}

export default new Api();
