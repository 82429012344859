import Api from '../api';
import { gbLoader } from '../helpers';
import { isAlphanumeric, isEmail, isNumeric, isRequired } from '../validate';
import Form from './Form';

class Configuration extends Form {
    private require: Partial<configurationData<Function>>;
    private preview: HTMLImageElement;
    
    constructor() {
        super();

        this.preview = document.getElementById('previewImage') as HTMLImageElement;
        this.require = {
            cif: isRequired,
            empresa: isRequired,
            direccion1: isRequired,
            municipio: isRequired,
            provincia: isRequired,
            codigoPostal: isNumeric,
            telefono1: isRequired,
            email: isEmail,
            serieFactura: isAlphanumeric,
            seriePresupuesto: isAlphanumeric,
            logoUrl: this.isValidUrlImage,
        };
        
        this.get();
        this.previewLogo();
    }

    private async get() {
        const data = await Api.getConf();
        if (data) {
            this.hydrate(data);

            if (data.logoUrl && data.logoUrl !== '') this.preview.src = data.logoUrl;
        }

        gbLoader(false);
    }

    protected async set() {
        this.require.logoUrl = this.fields['logoUrl'].value !== '' ? this.isValidUrlImage : () => true;
        
        if (this.validate(this.require)) {
            this.tabErrors();
            return;
        }

        gbLoader();
        const data = this.getValues<configurationData>();
        Api.putConf(data)
            .then((res) => {
                if (res.success) {
                    this.success();
                } else {
                    this.error(res.error);
                }
            })
            .finally(() => gbLoader(false))
    }

    private tabErrors() {
        document.querySelectorAll('.has-error').forEach(item => {
            const tab = (item.closest('.content') as HTMLElement).dataset.content;
            document.querySelector(`.tab[data-content="${tab}"]`)?.classList.add('has-error');
        });
    }

    private previewLogo() {
        const noImage = this.preview.src;
        const input = this.fields['logoUrl'];

        input.addEventListener('blur', () => {
            const validPreview = this.isValidUrlImage(input.value);
            this.preview.src = validPreview ? input.value : noImage;
        });
    };

    private isValidUrlImage(url: string) {
        const types = ['jpg', 'jpeg', 'png', 'svg', 'webp'];
        const urlPattern = new RegExp('^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$','i');
        const validUrl = urlPattern.test(url);
        const format = url.split('.').pop();
    
        return validUrl && format && types.includes(format);
    }
}

export interface configurationData<T = string> {
    cif: T;
    empresa: T;
    direccion1: T;
    direccion2: T;
    municipio: T;
    provincia: T;
    codigoPostal: T;
    telefono1: T;
    telefono2: T;
    email: T;
    serieFactura: T;
    serieTicket: T;
    seriePresupuesto: T;
    notasFactura: T;
    notasTicket: T;
    notasPresupuesto: T;
    logoUrl: T;
}

export default Configuration;