import Component from "./component";

class Accordion extends Component {
    private items: HTMLElement[];
    
    constructor(element: HTMLElement) {
        super(element);
        this.items = [];

        this.containerElement.querySelectorAll('.item').forEach(item => {
            this.items.push(item as HTMLElement);
        });

        this.setEvents();
    }

    private setEvents() {
        this.items.forEach(item => {
            const title = item.querySelector('.title') as HTMLElement;
            
            title.addEventListener('click', ev => {
                this.toggle(ev.currentTarget as HTMLElement);
            });
        });
    }

    private toggle(title: HTMLElement) {
        const parent = title.parentElement as HTMLElement;
        
        this.items.forEach(item => {
            parent !== item && item.classList.remove('active');
        });

        parent.classList.toggle('active');
    }

    public static init() {
        const elements = document.querySelectorAll('.accordion');
        elements.forEach(element => new Accordion(element as HTMLElement));
    }
}

export default Accordion;