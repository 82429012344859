import { gbLoader } from '../helpers';
import { validate, resetErrors } from '../validate';
import Api from '../api';

export const loginRequired = () => {
    if (!checkLogin()) {
        redirectLogin();
    }
}

export const redirectLogin = (action?: string) => {
    const url = `/login${action ? `?action=${action}` : ''}`;
    window.location.href = url;
}

export const checkLogin = () => !!localStorage.getItem('gbToken');

export const login = async () => {
    const email = document.querySelector<HTMLInputElement>('#email');
    const password = document.querySelector<HTMLInputElement>('#password');

    if (email && password && handleValidation('login', email, password)) {
        gbLoader();

        await Api.doLogin({
            email: email.value,
            password: password.value,
        }).then(res => {
            if (res.success) {
                setToken(res.token);
                window.location.href = '/configuration';
            } else {
                handleError(res.error);
            }
        });
    }

    gbLoader(false);
};

export const register = async () => {
    const email = document.querySelector<HTMLInputElement>('#email');
    const password = document.querySelector<HTMLInputElement>('#password');
    const terms = document.querySelector<HTMLInputElement>('#politica');

    if (terms && !terms.checked) {
        handleError('Debe aceptar la política de privacidad');
        return;
    }

    if (email && password && handleValidation('register', email, password)) {
        gbLoader();

        await Api.doRegister({
            email: email.value,
            password: password.value,
        }).then(res => {
            if (res.success) {
                redirectLogin('activation');
            } else {
                handleError(res.error);
            }
        });
    }

    gbLoader(false);
};

export const forgot = async () => {
    const email = document.querySelector<HTMLInputElement>('#email');

    if (email && handleValidation('forgot', email)) {
        gbLoader();

        await Api.doForgot({
            email: email.value,
            password: '',
        }).then(res => {
            if (res.success) {
                redirectLogin('forgot');
            } else {
                handleError(res.error);
            }
        });
    }

    gbLoader(false);
};

export const reset = async () => {
    const token = document.querySelector<HTMLInputElement>('#token')?.value || '';
    const password = document.querySelector<HTMLInputElement>('#newPassword');
    const rePassword = document.querySelector<HTMLInputElement>('#newPasswordConfirmation');

    if (password && rePassword) {
        resetErrors();

        if (!validate(password.value, 'password')) {
            handleError('La nueva contraseña debe ser de al menos 8 caracteres y contener: una minúscula, una mayúscula, un número y un carácter especial.', password);
            return false;
        }

        if (password.value !== rePassword.value) {
            handleError('Las contraseñas no coinciden', rePassword);
            return false;
        }

        gbLoader();

        await Api.doReset(token, {
            newPassword: password.value,
            newPasswordConfirmation: rePassword.value,
        }).then(res => {
            if (res.success) {
                redirectLogin('reset');
            } else {
                handleError('Se ha producido un error al cambiar la contraña. Vuelva a "He olvidado mi contraseña" y repita el proceso.');
            }
        });

        gbLoader(false);
    }
}

export const logout = () => {
    localStorage.removeItem('gbToken');
    redirectLogin();
}

const setToken = (token: string) => localStorage.setItem('gbToken', token);

const handleValidation = (action: Actions, email: HTMLInputElement, password?: HTMLInputElement) => {
    resetErrors();

    if (!validate(email.value, 'email')) {
        handleError('Inserte un email válido', email);
        return false;
    }

    if (password && !validate(password.value, action === 'register' ? 'password' : 'required')) {
        handleError(
            action === 'register' ?
                'La contraseña debe ser de al menos 8 caracteres y contener: una minúscula, una mayúscula, un número y un carácter especial.' :
                'Inserte la contraseña',
            password);
        return false;
    }

    return true;
};



const handleError = (text?: string, element?: HTMLElement) => {
    const form = document.querySelector('form');
    if (form) {
        const message = document.createElement('p');
        message.className = 'message error';
        message.innerText = text || 'Se ha producido un error';

        form.prepend(message);
    }

    element && element.classList.add('has-error');
};

type Actions = 'login' | 'register' | 'forgot';
