const TOKEN_ERROR_CODES = {
    EXPIRED: 'TOKEN_EXPIRED',
    NOT_FOUND: 'TOKEN_NOT_FOUND',
};

export const ERROR = {
    BAD_REQUEST: 'BAD_REQUEST', // 400
    TOKEN: TOKEN_ERROR_CODES, // 401
    UNPROCESSABLE_ENTITY: 'UNPROCESSABLE_ENTITY', // 422
    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR', // 500
    UNKNOWN: 'UNKNOWN',
};

export const STATUS = {
    OK: 200,
    CREATED: 201,
    EMPTY: 204,
    UNAUTHORIZED: 401,
    ERROR: 500,
}