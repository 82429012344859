import Configuration from '../components/Configuration';
import { run } from '../helpers';
import { formContact } from './contact';
import { showCookies } from './cookies';
import { forgot, login, logout, register, reset } from './login';
import { printOrder } from './print';

export const runEvents = () => {
    togglePass();
    showCookies();
    doLogout();

    run('login', doLogin);
    run('register', doRegister);
    run('forgot', doForgot);
    run('password/reset', doReset);

    run('contacto', formContact);
    run('configuration', () => new Configuration());
    run('print-order', printOrder);
};

const togglePass = () => {
    const toggles = document.querySelectorAll('.toggle-pass i');
    toggles && toggles.forEach(toggle => {
        toggle.addEventListener('click', () => {
            const showing = toggle.className.indexOf('eye-slash') >= 0;
            const input = toggle.previousElementSibling as HTMLInputElement;
            input.type = showing ? 'password' : 'text';
            toggle.className = `fas fa-eye${showing ? '' : '-slash'}`;
        });
    });
};

const doLogin = () => {
    const button = document.getElementById('login');
    button && button.addEventListener('click', (e: Event) => {
        e.preventDefault();
        login();
    });
};

const doRegister = () => {
    const button = document.getElementById('register');
    button && button.addEventListener('click', (e: Event) => {
        e.preventDefault();
        register();
    });
};

const doForgot = () => {
    const button = document.getElementById('forgot');
    button && button.addEventListener('click', (e: Event) => {
        e.preventDefault();
        forgot();
    });
};

const doLogout = () => {
    const button = document.getElementById('logout');
    button && button.addEventListener('click', (e: Event) => {
        e.preventDefault();
        logout();
    });
};

const doReset = () => {
    const button = document.getElementById('reset');
    button && button.addEventListener('click', (e: Event) => {
        e.preventDefault();
        reset();
    });
};
