const cookiesConfirm = () => !!localStorage.getItem('gbCookies');

const setCookiesConfirm = () => localStorage.setItem('gbCookies', '1');

export const showCookies = () => {
    if (!cookiesConfirm()) {
        const template = require('Templates/partials/cookies.twig') ;
        const content = document.createElement('div');
        content.className = 'box-cookies';
        content.innerHTML =  template({ BASE_URL }); 
        document.body.appendChild(content);

        const confirm = document.querySelector<HTMLAnchorElement>('#cookiesConfirm');
        confirm?.addEventListener('click', (e: Event) => {
            e.preventDefault();
            setCookiesConfirm();
            content.remove();
        });
    }
};