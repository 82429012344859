import Component from "./component";

class Tabs extends Component {
    private items: Array<TabElement>;
    
    constructor(element: HTMLElement) {
        super(element);
        this.items = [];

        this.containerElement.querySelectorAll('.tab').forEach(item => {
            const tab = item as HTMLElement;
            const data = tab.dataset.content;

            if (data) {
                const content = this.containerElement.querySelector(`.content[data-content="${data}"]`) as HTMLElement;
                content && this.items.push({ tab, content });
            }
        });

        this.setEvents();
    }

    private setEvents() {
        this.items.forEach(item => {
            item.tab.addEventListener('click', () => this.show(item));
        });
    }

    private show(item: TabElement) {
        if (item.tab.classList.contains('active')) return;

        this.items.forEach(item => {
            item.tab.classList.remove('active');
            item.content.classList.remove('show', 'active');
        });

        item.tab.classList.add('active');
        item.content.classList.add('show');
        setTimeout(() => item.content.classList.add('active'), 50);
    }

    public static init() {
        const elements = document.querySelectorAll('.tab-content');
        elements.forEach(element => new Tabs(element as HTMLElement));
    }
}

interface TabElement {
    tab: HTMLElement;
    content: HTMLElement;
}

export default Tabs;